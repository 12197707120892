/* Import Wizard Styles */

/* Container styles */
.wizard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  }
  
  .wizard-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    margin: 0; /* Remove bottom margin to align with the close button */
  }
  
  /* Progress steps */
  .progress-container {
    margin-bottom: 32px;
  }
  
  .progress-list {
    display: flex;
    align-items: center;
  }
  
  .progress-step {
    position: relative;
  }
  
  .progress-step:not(:first-child) {
    padding-left: 60px;
  }
  
  .progress-step:not(:last-child) {
    padding-right: 60px;
  }
  
  .progress-line {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 2px;
  }
  
  .progress-line-active {
    background-color: #2563eb;
  }
  
  .progress-line-inactive {
    background-color: #e5e7eb;
  }
  
  .progress-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
  
  .progress-circle-active {
    background-color: #2563eb;
    color: white;
  }
  
  .progress-circle-current {
    background-color: #2563eb;
    border: 2px solid #2563eb;
    color: white;
  }
  
  .progress-circle-inactive {
    background-color: white;
    border: 2px solid #d1d5db;
    color: #6b7280;
  }
  
  .progress-step-label {
    position: absolute;
    bottom: -24px;
    /* left: 50%; */
    transform: translateX(-20%);
    font-size: 12px;
    font-weight: 500;
    color: #6b7280;
    white-space: nowrap;
  }
  
  /* Section headings */
  .section-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  
  .section-content {
    margin-bottom: 24px;
  }
  
  /* App selection grid */
  .app-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
  }
  
  .app-card {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .app-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .app-card-selected {
    border-color: #2563eb;
    background-color: #eff6ff;
  }
  
  .app-info {
    display: flex;
    align-items: center;
  }
  
  .app-icon {
    font-size: 24px;
    margin-right: 12px;
    width: 32px;
  }
  
  .app-name {
    font-weight: 500;
  }
  
  .app-check {
    margin-left: auto;
    color: #2563eb;
  }
  
  /* Card styles */
  .info-card {
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
  }
  
  .info-icon {
    font-size: 48px;
    margin-bottom: 16px;
  }
  
  .info-icon-success {
    color: #10b981;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .card-description {
    color: #6b7280;
    margin-bottom: 24px;
  }
  
  .oauth-helper-text {
    margin-top: 16px;
    font-size: 14px;
    color: #6b7280;
  }
  
  /* Buttons */
  .btn {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
  }
  
  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .btn-primary {
    background-color: #2563eb;
    color: white;
  }
  
  .btn-primary:hover:not(:disabled) {
    background-color: #1d4ed8;
  }
  
  .btn-secondary {
    background-color: white;
    color: #4b5563;
    border: 1px solid #d1d5db;
  }
  
  .btn-secondary:hover:not(:disabled) {
    background-color: #f9fafb;
  }
  
  .btn-icon {
    margin-right: 8px;
  }
  
  .btn-icon-right {
    margin-left: 8px;
  }
  
  /* Client table */
  .table-container {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: scroll;
    max-height: 300px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header {
    background-color: #f9fafb;
  }
  
  .table-header th {
    padding: 12px 24px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color: #6b7280;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .table-row {
    border-top: 1px solid #e5e7eb;
    cursor: pointer;
  }
  
  .table-row:hover {
    background-color: #f9fafb;
  }
  
  .table-row-selected {
    background-color: #eff6ff;
  }
  
  .table-cell {
    padding: 6px 24px;
    white-space: nowrap;
  }
  
  .checkbox {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
  
  .client-name {
    font-weight: 500;
    color: #111827;
  }
  
  .client-detail {
    font-size: 14px;
    color: #6b7280;
  }
  
  .badge {
    display: inline-block;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 9999px;
  }
  
  .badge-active {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .badge-inactive {
    background-color: #f3f4f6;
    color: #4b5563;
  }
  
  /* Selection counter */
  .selection-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 16px; */
  }
  
  .selection-count {
    font-size: 14px;
    color: #6b7280;
  }
  
  /* Navigation */
  .navigation {
    margin-top: 32px;
    padding-top: 20px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
  }
  
  /* Stats */
  .stats-container {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-bottom: 24px;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: 700;
  }
  
  .stat-value-total {
    color: #4b5563;
  }
  
  .stat-value-success {
    color: #10b981;
  }
  
  .stat-value-failed {
    color: #ef4444;
  }
  
  .stat-label {
    font-size: 14px;
    color: #6b7280;
  }
  
  /* Loading spinner */
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* OAuth info box */
  .oauth-info {
    margin-bottom: 16px;
    padding: 12px;
    background-color: #eff6ff;
    color: #1e40af;
    border-radius: 6px;
    font-size: 14px;
    text-align: left;
  }
  
  .oauth-info-title {
    font-weight: 500;
  }
  
  .oauth-info-detail {
    margin-top: 4px;
  }

  .table-container.scrollable {
  max-height: 400px;
  overflow-y: auto;
}

.table-container.limited-scroll {
  max-height: 200px; /* Adjust based on the height of 4 records */
  overflow-y: auto;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.wizard-container {
  padding-bottom: 10px; /* Ensure there's space for the fixed navigation */
}

.wizard-content {
  max-height: calc(100vh - 160px); /* Adjust based on header and footer height */
  overflow-y: auto;
  padding-right: 16px; /* Add some padding to avoid scrollbar overlap */
}

.close-button-wiz {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
}

.wizard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}