.chat-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chat-body {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-footer textarea {
  flex-grow: 1;
  margin-right: 10px;
}

.chat-footer button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.chat-footer button:hover {
  background-color: #0056b3;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message-right {
  text-align: right;
}

.chat-message-left {
  text-align: left;
}

.chat-message-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.chat-message-body {
  
  color: #333;
}
