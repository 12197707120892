/* Editor.css */
.editor-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .content-preview {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  .editor-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .content-preview h3 {
    font-size: 20px;
  }
  
  .editor-container .ProseMirror {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 300px;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  
  .editor-container .ProseMirror p {
    margin-bottom: 10px;
  }
  
  .editor-container .ProseMirror .task-list {
    list-style-type: none;
    padding: 0;
  }
  
  .editor-container .ProseMirror .task-item {
    display: flex;
    align-items: center;
  }
  
  .editor-container .ProseMirror .task-item input {
    margin-right: 10px;
  }
  
  .editor-container .ProseMirror table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .editor-container .ProseMirror th, .ProseMirror td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .editor-container .ProseMirror th {
    background-color: #f2f2f2;
  }
  