.p-autocomplete-multiple-container .p-autocomplete-token {
  background-color: darkgray;
  color: white;
}

.task-form-panel .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.task-form-panel .close-button svg {
  color: darkgray;
}

.task-form-panel .close-button:hover {
  background: none; /* Ensure background does not change */
}

.task-form-panel .close-button:hover svg {
  color: black; /* Change to the desired hover color */
}

.messages-section {
  margin-top: 20px;
}

.message {
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.message-user {
  font-weight: bold;
  color: #333;
}

.message-time {
  font-size: 12px;
  color: #888;
}

.message-body {
  margin-top: 5px;
  color: #555;
}

.editor-container {
  margin-top: 20px;
}

.submit-message-button {
  margin-top: 10px;
}

.task-record-link {
  margin-top: 10px;
}

.task-record-link a {
  color: #007bff;
  text-decoration: none;
}

.task-record-link a:hover {
  text-decoration: underline;
}
