.side-panel-acct {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.side-panel-content {
  padding: 20px;
  box-sizing: border-box;
}

.filter-dropdown {
  margin-left: 10px;
  width: 200px;
}

.filter-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #0056b3;
}

.filter-panel {
  position: absolute;
  top: calc(100% + 5px); /* Position immediately under the Filter button */
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  left: 110px;
}

.filter-panel h4 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.filter-panel .p-dropdown,
.filter-panel .p-calendar,
.filter-panel .p-multiselect {
  width: 100%;
  margin-bottom: 10px;
}

.filter-panel button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.filter-panel button:hover {
  background-color: #0056b3;
}

.filter-panel .cancel-button {
  background-color: #6c757d;
  margin-left: 10px;
}

.filter-panel .cancel-button:hover {
  background-color: #5a6268;
}

.filter-panel .button-group {
  display: flex;
  justify-content: flex-end;
}

.filter-panel .clear-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
}

.filter-panel .clear-button {
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  font-size: small;
  margin-top: -10px;
}

.filter-panel .clear-button:hover {
  color: #0056b3;
  background-color: transparent;
}

.filter-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}