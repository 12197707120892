@import 'components/global.scss';

.feedback {

  position: fixed;
  // right: $scale;
  // // bottom: $scale;
  // left: $scale;
  // margin: auto;
  margin-left: 15%;
  width: 60%;
  background-color: $c-dark;
  z-index: 1000;

  header {

    color: white;
    background-color: transparent;
    border-bottom: 1px dotted lighten($c-dark, 10%);

  }

  form {

    margin-top: $scale2;

    textarea + svg {

      display: none;

    }
  }

  // @media (min-width: $mobile){

  //   left: auto;
  //   width: $scale14;

  // } 
}

.rating {

  text-align: center;

  button {

    margin-right: $scale;
    width: $scale5;
    height: $scale5;
    transition: 0.15s ease-in-out all;

    &:hover {

      transform: scale(1.2);

    }

    &:last-child {

      margin-right: 0;

    }
  }
}

.close {

  position: absolute;
  top: $scale;
  right: $scale;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;

  &:hover {

    opacity: 1;

  }
}

.p-calendar {
  .p-inputtext {
    border: 1px solid #cccccc;
    padding: $scale;
    border-radius: 1px;
  }
}