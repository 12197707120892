.chart-settings-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%; /* Take up the whole main part of the screen */
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.fixed-header {
  flex-shrink: 0; /* Prevent shrinking */
}

.chart-settings-form h2 {
  /* margin-bottom: 20px; */
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; /* Prevent shrinking */
}

.content-wrapper {
  display: flex;
  height: calc(100% - 60px); /* Adjust height to account for header */
}

.chart-preview {
  width: 50%;
  margin-right: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.scrollable-content {
  width: 50%;
  overflow-y: auto;
  flex-grow: 1;
}

.form-group {
  margin-bottom: 15px;
  flex-shrink: 0; /* Prevent shrinking */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="text"],
.form-group select {
  width: 100%;
  padding: 10px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
 background-color: #f9f9f9; 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group .p-inputnumber {
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
 background-color: #f9f9f9; 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.form-group .p-treeselect {
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
 background-color: #f9f9f9; 
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input[type="text"]:focus,
.form-group select:focus,
.form-group .p-treeselect:focus,
.form-group .p-inputnumber:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-group input[type="checkbox"] {
  margin-right: 10px;
}

.form-group .p-inputswitch {
  /* margin-left: 10px; */
  margin-top: 10px
}

.switch-group {
  display: flex;
  align-items: centecr;
  gap: 10px;
  flex-shrink: 0; /* Prevent shrinking */
}

.data-group {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f1f1f1;
  flex-shrink: 0; /* Prevent shrinking */
}

.data-group .form-group {
  margin-bottom: 10px;
}

.data-group-header {
  display: flex;
  justify-content: flex-end;
}

.filter-group {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f1f1f1;
  flex-shrink: 0; /* Prevent shrinking */
}

.filter-group .form-group {
  margin-bottom: 10px;
}

.add-button,
.remove-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-button:hover,
.remove-button:hover {
  background-color: #0056b3;
}

.remove-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s ease;
}

.remove-button:hover {
  color: #0056b3;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0; /* Prevent shrinking */
}

.save-button,
.cancel-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.save-button:hover,
.cancel-button:hover {
  background-color: #0056b3;
}

.chart-settings-form > :not(.button-group) {
  overflow-y: auto;
  flex-grow: 1;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.form-row .form-group {
  flex: 1;
  min-width: 200px;
}

.form-row .form-group button {
  width: 100%;
}
