@import 'components/global.scss';

.hero {

  position: relative;
  color: white;
  text-align: left;
  margin-bottom: $scale2;
  padding-top: $scale;

  h1 {

    display: block;
    font-size: $scale3;
    font-weight: 800;
    margin-bottom: 0;

  }

  h2 {

    display: block;
    color: white;
    font-weight: normal;
    font-size: $scale1;
    margin: $scale-1 0 $scale;

  }

  img {

    margin-bottom: -$scale2;
    box-shadow: 0 $scale $scale1 rgba(0, 0, 0, 0.2);

  }

  &:before {

    content: '';
    position: absolute;
    top: -$scale9;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: $c-blue;
    background-image: radial-gradient(closest-corner, $c-blue, $c-darkblue);

  }

  @media (min-width: 35em){

    text-align: center;
    padding-top: $scale5;

    h1 {

      font-size: $scale4;

    }

    h2 {

      font-size: $scale1;

    }
  }
}

.blurb {

  margin-bottom: $scale3;

  @media (min-width: 35em){

    max-width: $scale16;
    margin-left: auto;
    margin-right: auto;

  }

  @media (min-width: 50em){

    max-width: $scale18;
    margin-bottom: $scale5;

  }
}
