@import 'components/global.scss';
$grey: #aaa;

.facebook, .twitter, .microsoft, .linkedin {

  color: white;
  transition: 0.2s ease-in-out color; 

  &:hover {

    color: white !important;

  }
}


.magic {

  background-color: $c-green;

}

.mail {

  background-color: $c-green;

  &:hover {

    background-color: darken($c-green, 5%);

  }
}

.icon {

  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.signinButtons {

  text-align: center;
  margin-top: -$scale3;
  margin-bottom: $scale3;

  > div {

    width: 100%;

  }

  button {

    margin-bottom: $scale-4;

  }
} 

.shareButtons {

  text-align: center;
  margin: 0 auto;

  a {

    position: relative;
    float: left;
    width: $scale4;
    height: $scale4;
    margin-right: 0.1em;
    padding: $scale-2;
    border-radius: $radius;

    svg {

      position: absolute;
      left: 50%;
      top: 50%;
      width: $scale1;
      height: $scale1;
      transform: translate(-50%, -50%);

    }

    &:last-child {

      margin-right: 0;

    }
  }
}

.or {

  position: relative;
  display: inline-block;
  margin-top: $scale-2;
  padding: 0 $scale-2;
  width: 100%;

  &:before {

    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    z-index: -2;
    margin-top: 1px;
    background-color: $c-border;
    transform: translateY(-50%);

  }  

  &:after {

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $scale4;
    height: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: white;

  }
}