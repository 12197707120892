@import 'components/global.scss';
$bp: $scale17;

.wrapper {

  @media (max-width: $mobile){

    width: 95% !important;
    margin: $scale6 auto 0;

  }
}

.onboarding {

  position: relative;
  box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.15);
  border-radius: $radius;
  overflow: hidden;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);

  @media (min-width: $bp){

    width: $scale17;
    margin-top: $scale5;
    min-height: $scale15;

  }
}

.sidebar {

  color: white;
  padding: $scale2;
  border-top-left-radius: $radius;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);

  > a:first-child {

    margin: 0;

  }
  
  @media (max-width: $bp){

    border-top-right-radius: $radius;

  }

  @media (min-width: $bp){

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: $radius;
    width: $scale11 + $scale;

  }
}

.main {

  position: relative;
  min-height: $scale15;
  margin-bottom: $scale5;
  padding: $scale2 $scale2 $scale6 $scale2;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  header {

    width: 100%;
    background-color: transparent;
    border-bottom: 1px dotted $c-border;

    > h2 {

      text-transform: capitalize;
      margin: 0 0 $scale-4 0;

    }

    > span {

      font-size: 0.9em;
      color: lighten($c-text, 12%);
      
    } 
  }

  > img {

    max-height: $scale12;
    margin: 0 auto;

  }

  @media (max-width: $bp){

    width: 100%;

  }

  @media (min-width: $bp){

    margin-left: $scale11 + $scale;

    > p {

      // width: 45%;

    }

    > img {

      position: absolute;
      width: $scale12;
      top: $scale5;
      right: $scale1;
      z-index: 1;
      
    }
  }
}

.checklist {

  @media (max-width: $bp){

    margin-top: $scale1;

  }

  @media (min-width: $bp){

    position: absolute;
    top: 50%;
    left: $scale2;
    margin-top: $scale1;
    transform: translateY(-50%);

  }
}

.nav {

  position: absolute;
  bottom: $scale;
  left: $scale2;
  right: $scale2;

  button {
    // padding: $scale $scale1;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #009ffd;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #007bbd;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 159, 253, 0.5);
    }
  }

  @media (min-width: $bp){

    left: $scale12;

  }
}

.prev {

  float: left;
  margin-right: $scale2;

}

.next {

  float: right;
  margin-left: $scale2;

}

.skip {

  position: absolute !important;
  top: -$scale4;
  right: 0;
  color: rgba(255, 255, 255, 0.8);

}

.connect-form {
  margin-top: $scale4;
  padding: $scale2;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: $radius;

  input {
    width: 100%;
    padding: $scale1;
    margin-bottom: $scale2;
    border: 1px solid #ccc;
    border-radius: $radius;
  }

  button {
    padding: $scale1 $scale3;
    background-color: #009ffd;
    color: #ffffff;
    border: none;
    border-radius: $radius;
    cursor: pointer;

    &:hover {
      background-color: #007bbd;
    }
  }
}