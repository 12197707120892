/* KanbanBoard.css */


.kanban-board {
  display: flex;
  gap: 10px;
  overflow-x: auto; /* Allow horizontal scrolling */
  height: calc(100vh - var(--top-offset));
  overflow: hidden; /* Prevent page from scrolling */
}

.kanban-column {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 10px;
  padding-top: 0px;
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Ensure column takes full height */
  overflow-y: auto; /* Make the column itself scrollable */
}

.kanban-column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 1;
  padding-bottom: 10px;
}

.add-task-button {
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.add-task-button:hover {
  background-color: #0056b3;
}

.kanban-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}

.kanban-card h4 {
  margin: 0 0 10px;
  padding-right: 40px; /* Add padding to prevent overlay */
}

.kanban-card p {
  margin: 5px 0;
}

.kanban-card-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.new-card-form {
  display: flex;
  flex-direction: column;
}

.new-card-form input,
.new-card-form textarea {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.new-card-form button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.new-card-form button:hover {
  background-color: #0056b3;
}


.on-hold-divider {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
}

.on-hold-drop-area {
  border: 2px dashed blue;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.text-field {
  font-size: 12pt;
}

.kanban-card-avatar {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.avatar-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}