@import 'components/global.scss';

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  thead {
    background-color: #f5f5f5;
    font-weight: 600;

    th {
      text-align: left;
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      padding: $scale-1 $scale-2 $scale-2;
      border-bottom: 1px solid #e0e0e0;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  tbody {
    tr {
      background-color: #fff;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f9f9f9;
      }

      &:last-child td {
        border-bottom: 0;
      }
    }

    td {
      padding: $scale-2;
      border-bottom: 1px solid #e0e0e0;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  @media (max-width: $mobile) {
    thead {
      display: none;
    }

    tbody {
      tr {
        td:last-child {
          padding-bottom: $scale;
          border-bottom: 1px solid $c-border;
        }

        &:last-child td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {
        float: left;
        width: 100%;
        padding: 0;
        margin-bottom: $scale-2;

        > span {
          margin: 0; // reset badge
        }
      }
    }
  }

  @media (min-width: $mobile) {
    tbody td {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.sort {
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 1em;
    height: 0.5em;
    opacity: 0.5;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    background-size: contain;
  }
}

.asc:after {
  background-image: url("./icons/ico-sort-asc.svg");
}

.desc:after {
  background-image: url("./icons/ico-sort-dsc.svg");
}

.search {
  margin-bottom: $scale2;
}

tr > th.actions {
  text-align: right;
}

.actions {
  text-align: right;
  white-space: nowrap;

  a, button {
    display: inline-block;
    width: 1.1em;
    height: 1.1em;
    opacity: 0.7;
    font-size: 1em;
    white-space: nowrap;
    margin-right: $scale-3;
    background-color: transparent;
    transition: opacity 0.2s ease-in-out;

    &:last-child {
      margin-right: 0;
    }

    .ico {
      width: 1.1em;
      height: 1.1em;
    }

    &:hover {
      opacity: 1;
    }
  }

  .hyperlink {
    font-size: 1em;
    color: #707070;
  }

  @media (max-width: $mobile) {
    text-align: left;
  }
}

.loading {
  position: relative;
  padding: $scale5 0;
}
