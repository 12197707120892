.actionBarBtn {
    margin-right: 6px;
    width: 90px;
    height: 30px;
    padding-top: 6px;
}

.saveBtn {
    margin-top: 6px;
    float: right;
}

.section {
    padding-bottom: 30px;
}

.selectedEvent {
    background-color:burlywood;
}

.compareEvent {
    background-color: gray;
}

.label {
    padding-right: 6px;
}

.inputText {
    font-size: 14;
}

.jsonNode {
    margin-left: 20px; /* Adjust the indentation as needed */
    margin-right: 20px;
    background-color: rgb(129, 190, 111);
    padding-bottom: 14px;
    padding-top: 14px;
    border: 2px solid gray;
    border-radius: 10px;
    color: white;
    min-height: 70px;
  }

  .surroundingNode {
    align-self: center;
    max-width: 700px;
    margin-left: 20px; /* Adjust the indentation as needed */
    margin-right: 20px;
    padding-bottom: 24px;
    padding-top: 24px;
    border: 2px solid gray;
    border-radius: 10px;
    background-color: rgb(238, 237, 237);
  }

  .surroundingOutermostNode {
    align-self: center;
    max-width: 700px;
    margin-left: 20px; /* Adjust the indentation as needed */
    margin-right: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    border-radius: 10px;
    margin: auto;
  }

  .expression {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .panelButton {
    margin-right: 6px;
  }

  .operatorBtn {
    text-decoration: underline;
    padding: 0;
    text-transform: uppercase;
  }

  .expressionWrapper {
    margin-right: 10px;
    width: 80%;
    overflow: clip;
    float: left;
    margin-top: 0px;
  }

  .editExpression {
    height: 200px;
    
  }