.actionBarBtn {
    margin-right: 6px;
    width: 90px;
    height: 30px;
    padding-top: 6px;
}

.actionBarBtnLg {
    margin-right: 6px;
    height: 30px;
    padding-top: 6px;
}

.jsonNode {
    margin-left: 20px; /* Adjust the indentation as needed */
    padding-left: 20px;
}

.auditRuleText {
    padding-left: 10px;
    padding-right: 20px;
}

.saveBtn {
    margin-top: 6px;
    float: right;
}

.section {
    padding-bottom: 30px;
}

.selectedEvent {
    background-color:#55acee;
    border-radius: 4px;
}

.compareEvent {
    background-color: gray;
    border-radius: 4px;
}

.label {
    padding-right: 6px;
}

.inputText {
    font-size: 14;
}

.hoverNav {
    display: 'flex';
    float: left;
    
}


