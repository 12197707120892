.side-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #f9f9f9;
    border-left: 2px solid #ccc;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .side-panel-content {
    padding: 20px;
  }
  
/*   
  .chart-container {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-actions button {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  position: relative;
}

.chart-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
