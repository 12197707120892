nav {
  margin-bottom: 20px;
}

nav button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

nav button:hover {
  background-color: #0056b3;
}


.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}


nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #007bff;
}

nav a.active {
  font-weight: bold;
  text-decoration: underline;
}

.subtask-container {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.subtask-container h5 {
  margin-top: 0;
  color: #333;
}

.subtask-table .p-datatable-header {
  background-color: #007bff;
  color: white;
}

.subtask-table .p-datatable-tbody > tr > td {
  border: none;
  font-size: 12px; /* Smaller font size for table columns */
}

.chat-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: white;
  border-left: 2px solid #ccc;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: white;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-footer textarea {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.chat-footer button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-footer button:hover {
  background-color: #0056b3;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.chat-message-body {
  padding: 5px;
  border-radius: 4px;
}

.chat-message-left .chat-message-body {
  background-color: #f1f1f1;
}

.chat-message-right {
  text-align: right;
}

chat-message-right .chat-message-body {
  background-color: #007bff;
  color: white;
}

.comments-cell {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.comments-cell svg {
  margin-right: 5px;
}

.p-badge {
  margin-left: -15px;
  margin-top: 15px;
  background-color: gray;
  font-size: 10pt;
}

.icon {
  position: relative;
  top: 2px;
}

.add-task-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.task-form-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #f9f9f9;
  border-left: 2px solid #ccc;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.task-form-content {
  padding: 20px;
}

.task-form-panel h4 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.task-form-panel input,
.task-form-panel textarea,
.task-form-panel .p-dropdown,
.task-form-panel .p-calendar {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #333;
}

.task-form-panel button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.task-form-panel button:hover {
  background-color: #0056b3;
}

.task-form-panel .cancel-button {
  background-color: #6c757d;
  margin-left: 10px;
}

.task-form-panel .cancel-button:hover {
  background-color: #5a6268;
}

.task-form-panel .button-group {
  display: flex;
  justify-content: flex-end;
}

.edit-icon {
  margin-left: 10px;
  cursor: pointer;
  visibility: hidden;
}

.p-datatable-tbody > tr:hover .edit-icon {
  visibility: visible;
}

.editable:hover .edit-icon {
  visibility: visible;
}

.expander-cell {
  display: flex;
  align-items: center;
}

.filter-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #0056b3;
}

.filter-panel {
  position: absolute;
  top: calc(100% + 5px); /* Position immediately under the Filter button */
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  left: 110px;
}

.filter-panel h4 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.filter-panel .p-dropdown,
.filter-panel .p-calendar,
.filter-panel .p-multiselect {
  width: 100%;
  margin-bottom: 10px;
}

.filter-panel button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.filter-panel button:hover {
  background-color: #0056b3;
}

.filter-panel .cancel-button {
  background-color: #6c757d;
  margin-left: 10px;
}

.filter-panel .cancel-button:hover {
  background-color: #5a6268;
}

.filter-panel .button-group {
  display: flex;
  justify-content: flex-end;
}

.filter-panel .clear-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
}

.filter-panel .clear-button {
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  font-size: small;
  margin-top: -10px;
}

.filter-panel .clear-button:hover {
  color: #0056b3;
  background-color: transparent;
}

.filter-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completed {
  background-color: green;
  color: white;
  border-radius: 50%;
}

.completed-task-row {
  color: lightgray;
  font-style: italic;
}

.past-due-task{
  color: red;
}

.task-row {
  font-size: 11pt;
}

.assigned-to-avatar {
  display: flex;
  align-items: center;
}

.avatar-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.assigned-to-name {
  font-size: 12px;
}

@media (max-width: 768px) {
  .kanban-board {
    flex-direction: column;
    height: auto;
  }

  .kanban-column {
    width: 100%;
    margin-bottom: 20px;
  }

  .filter-panel {
    width: 100%;
    left: 0;
    top: calc(100% + 5px);
  }

  .task-form-panel {
    width: 100%;
  }

  .chat-panel {
    width: 100%;
  }

  .add-task-button,
  .filter-button {
    width: 100%;
  }

  .button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .button-group button {
    width: 100%;
    margin-bottom: 10px;
  }

  .edit-icon {
    visibility: visible !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .kanban-board {
    flex-wrap: wrap;
  }

  .kanban-column {
    width: 48%;
    margin-bottom: 20px;
  }

  .filter-panel {
    width: 50%;
    left: 25%;
  }

  .task-form-panel {
    width: 75%;
  }

  .chat-panel {
    width: 75%;
  }

  .add-task-button,
  .filter-button {
    width: auto;
    margin-bottom: 0;
  }

  .button-group {
    flex-direction: row;
    align-items: center;
  }

  .button-group button {
    width: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 1025px) {
  .button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .add-task-button,
  .filter-button {
    width: auto;
    /* margin-bottom: 0; */
  }
}

.status-cell 
{
  width: 150px
}

.comments-section {
  margin-top: 20px;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 16px; /* Larger font size */
}

.comment-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px; /* Larger font size */
  color: #555;
}

.comment-body {
  margin-top: 5px;
  font-size: 16px; /* Larger font size */
}

.subtasks-section {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
}

.subtask {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 16px; /* Larger font size */
}

.subtask-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px; /* Larger font size */
  color: #555;
}

.subtask-title a {
  font-weight: bold;
  text-decoration: none;
  color: #007bff;
}

.subtask-title a:hover {
  text-decoration: underline;
}

.subtask-time {
  margin-left: 10px;
  font-size: 14px; /* Larger font size */
  color: #555;
}

.subtask-status {
  margin-left: 10px;
  font-size: 14px; /* Larger font size */
  color: #555;
}

.breadcrumb {
  margin-bottom: 10px;
}

.breadcrumb a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.subtask-check-icon {
  max-width: 30px;
  display: inline-block;
}

.completed-check-icon {
  border-radius: 50%;
  border: 2px solid green;
  background-color: green;
  color: white;
  padding: 2px;
  cursor: pointer; /* Change cursor to pointer */
}

.incomplete-check-icon {
  border-radius: 50%;
  border: 2px solid darkgray;
  background-color: transparent;
  padding: 2px;
  cursor: pointer; /* Change cursor to pointer */
}

.task-title {
  padding-left: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 30px;
  width: 140px; /* Set width to 100px */
  display: flex;
  align-items: center; /* Center the text vertically */
}
.task-overdue {
  border-left: 5px solid red;
}

.task-due-soon {
  border-left: 5px solid orange;
}

.task-future {
  border-left: 5px solid green;
}

.completed-task-row .task-title {
  border-left: none;
}

.p-component {
  box-shadow: none !important;
}

.timeline {
  position: relative;
  padding: 10px 0;
  list-style: none;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #007bff;
  left: 20px;
  margin-left: -1.5px;
}

.timeline-item {
  margin-bottom: 20px;
  position: relative;
}

.timeline-item:before,
.timeline-item:after {
  content: '';
  display: table;
}

.timeline-item:after {
  clear: both;
}

.timeline-icon {
  position: absolute;
  left: 0;
  background: #007bff;
  color: white;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  top: 0;
}

.timeline-content {
  margin-left: 60px;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}

.timeline-time {
  display: block;
  margin-top: 5px;
  color: #999;
  font-size: 12px;
}

.messages-cell {
  display: flex;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer */
}

.messages-cell svg {
  margin-right: 5px;
}